import { useReactiveVar, useQuery } from "@apollo/client";
import { useInfiniteQuery, useIsFetching } from "@tanstack/react-query";
import { useMemo, memo } from "react";
import { IReview } from "../../@types/rest/reviews";
import { GET_STORES } from "../../api/graphql/stores";
import { getReviewsV2, getReviewsKeyV2 } from "../../api/rest/getReviewsV2";
import { ButtonSwitcher } from "../../components/common/buttonSwitcher";
import { CustomButton } from "../../components/common/customButton";
import { CustomInput } from "../../components/common/customInput";
import { FullContainerSpinner } from "../../components/common/fullContainerSpinner";
import { SelectList } from "../../components/common/selectList";
import { useDebounce } from "../../hooks/useDebounce";
import { reviewsFilter } from "../../store/reviews.store";
import { chosenStore } from "../../store/stores.store";
import { toolbarContentVar } from "../../store/toolbar.store";

import Star from "../../assets/icons/starFilled.svg?react";
import Arrow from "../../assets/icons/arrowGoTo.svg?react";
import Spinner from "../../assets/icons/spinner.svg?react";
import Search from "../../assets/icons/search.svg?react";
import { ratingElements, reviewsBtns } from "./config";

const ReviewsItems = memo(() => {
  const { loading } = useQuery(GET_STORES);
  const store = useReactiveVar(chosenStore);
  const filter = useReactiveVar(reviewsFilter);
  const { rating, interaction_status, query } = filter;
  const { debouncedValue } = useDebounce(query, 300, query);

  const { data, isLoading, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryFn: ({ pageParam }) =>
        getReviewsV2({
          storeId: store.id,
          lastTimeStamp: pageParam.lastTimeStamp.toString(),
          companyId: store.sellerClientId,
          lastUUID: pageParam.lastUUID,
          filter: {
            query: debouncedValue,
            rating: rating,
            interaction_status: interaction_status,
          },
        }),
      queryKey: [
        ...getReviewsKeyV2,
        JSON.stringify({ rating, interaction_status, debouncedValue }),
        store.sellerClientId,
      ],
      enabled: !!store,
      initialPageParam: {
        lastTimeStamp: Date.now() * 1000,
        lastUUID: undefined,
      },
      getNextPageParam: (lastPage) => {
        const r = lastPage.result[lastPage.result.length - 1];

        return {
          lastTimeStamp: new Date(r?.published_at).getTime() * 1000,
          lastUUID: r?.uuid,
        };
      },
    });

  const shouldShowSpinner = isLoading || loading;

  const dataToUse = useMemo(() => {
    const result: { data: IReview[]; hasNext: boolean } = {
      data: [],
      hasNext: false,
    };

    if (!data?.pages.length) return result;

    for (const page in data?.pages)
      result.data.push(...data.pages[page].result);

    result.hasNext = data?.pages[data.pages.length - 1].hasNext;

    return result;
  }, [data]);

  return (
    <div className="reviews-wrapper">
      {shouldShowSpinner ? (
        <FullContainerSpinner />
      ) : (
        <div className="reviews-items">
          {dataToUse.data.map((i) => (
            <div className="reviews-item" key={i.uuid + i.id}>
              <div className="reviews-item-content">
                <div>
                  <div
                    className="reviews-item-product-image"
                    style={{
                      // @ts-ignore
                      "--image-url": "url(" + i.product.cover_image + ")",
                    }}
                  />
                  <div className="reviews-item-btns">
                    <CustomButton
                      color="primary2"
                      onClick={() =>
                        toolbarContentVar({ data: i, type: "review" })
                      }
                    >
                      Подробнее
                    </CustomButton>
                    <CustomButton
                      color="primary2"
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(i.product.url, "_blank");
                      }}
                      leftIcon={Arrow}
                      size="icon"
                    />
                  </div>
                </div>
                <div className="reviews-item-right">
                  <div className="reviews-item-right-item">
                    <span className="reviews-item-right-item-title">
                      Покупатель:
                    </span>{" "}
                    {i.author_name}
                  </div>
                  <div className="reviews-item-right-item">
                    <span className="reviews-item-right-item-title">SKU:</span>{" "}
                    {i.sku}
                  </div>
                  <div className="reviews-item-right-item">
                    <span className="reviews-item-right-item-title">
                      Товар:
                    </span>{" "}
                    {i.product.title}
                  </div>
                  <div className="reviews-item-right-item">
                    <span className="reviews-item-right-item-title">
                      Статус:{" "}
                    </span>
                    {i.interaction_status === "VIEWED"
                      ? "Просмотренный"
                      : i.interaction_status === "PROCESSED"
                        ? "Обработанный"
                        : "Не просмотренный"}
                  </div>
                  <div className="reviews-item-right-item">
                    <span className="reviews-item-right-item-title">
                      Оценка:
                    </span>{" "}
                    <span className="reviews-item-rating">
                      {i.rating} <Star />
                    </span>
                  </div>
                  {i.text?.comment && (
                    <div className="reviews-item-right-item">
                      <span className="reviews-item-right-item-title">
                        Комментарий:
                      </span>{" "}
                      {i.text.comment}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {dataToUse.hasNext && !shouldShowSpinner && (
        <CustomButton
          isLoading={isFetchingNextPage}
          onClick={() => fetchNextPage()}
        >
          Загрузить еще
        </CustomButton>
      )}
    </div>
  );
});

ReviewsItems.displayName = "ReviewsItems";

export const ReviewsV2 = () => {
  const filter = useReactiveVar(reviewsFilter);
  const isFetching = useIsFetching({ queryKey: getReviewsKeyV2 });

  const clearSelectRating = () => {
    const newFilter = { ...filter };
    delete newFilter.rating;
    reviewsFilter(newFilter);
  };

  return (
    <>
      <div className="reviews-filters">
        <CustomInput
          className="reviews-filter-search"
          placeholder="Введите название или артикул"
          leftIcon={isFetching ? Spinner : Search}
          value={filter.query}
          onChange={(e) => {
            const value = e.target.value;

            reviewsFilter({ ...filter, query: value });
          }}
        />
        <ButtonSwitcher
          value={filter.interaction_status[0]}
          items={reviewsBtns}
          onChange={(item) => {
            reviewsFilter({
              ...filter,
              interaction_status: [item.value as any],
            });
          }}
        />
        <SelectList
          active={ratingElements.find((i) => i.value === filter.rating?.[0])}
          placeholder="Оценка"
          elements={ratingElements}
          handleClear={clearSelectRating}
          handleChange={(item) => {
            if (filter.rating?.[0] === item.value) return clearSelectRating();
            reviewsFilter({ ...filter, rating: [+item.value] });
          }}
        />
      </div>
      <ReviewsItems />
    </>
  );
};
