import { IReview } from "../../@types/rest/reviews";
import { TReviewsFilter } from "../../store/reviews.store";
import { axiosInstance } from "./axios";

export const getReviewsKeyV2 = ["getReviewsKeyV2"];
export const getReviewsV2 = async (params: {
  storeId: number;
  lastTimeStamp: string;
  companyId: string;
  lastUUID?: string;
  filter: TReviewsFilter;
}) => {
  const res = await axiosInstance.post<{ result: IReview[]; hasNext: boolean }>(
    `/reviews/${params.storeId}/v2`,
    {
      pagination_last_timestamp: params.lastTimeStamp,
      pagination_last_uuid: params.lastUUID,
      company_id: params.companyId,
      filter: params.filter,
    },
  );

  return res.data;
};
