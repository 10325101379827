import { IReviews } from "../../@types/rest/reviews";
import { axiosInstance } from "./axios";

export const getReviewsStatisticsKey = ["getReviewsStatisticsKey"];
export const getReviewsStatistics = async (params: {
  storeId: number;
  minDate: string;
  filterSku?: undefined;
}) => {
  const res = await axiosInstance.get<IReviews>(`/reviews`, {
    params,
  });

  return res.data;
};
