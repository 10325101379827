import { makeVar } from "@apollo/client";

export type TReviewsFilter = {
  query?: string;
  rating?: number[];
  interaction_status: ("ALL" | "NOT_VIEWED" | "VIEWED" | "PROCESSED")[];
};
export const reviewsFilter = makeVar<TReviewsFilter>({
  interaction_status: ["ALL"],
  query: "",
});
