export const ratingElements = [
  { name: "5 звезд", value: 5, id: "5" },
  { name: "4 звезды", value: 4, id: "4" },
  { name: "3 звезды", value: 3, id: "3" },
  { name: "2 звезды", value: 2, id: "2" },
  { name: "1 звезда", value: 1, id: "1" },
];

export const reviewsBtns = [
  { name: "Все", value: "ALL" },
  { name: "Новые", value: "NOT_VIEWED" },
  { name: "Просмотренные", value: "VIEWED" },
  { name: "Обработанные", value: "PROCESSED" },
];
