import { ReviewsV2 } from "./reviewsV2";

import "../../styles/pages/reviewsPage.css";

export const ReviewsPage = () => {
  return (
    <div className="default-page">
      <h2 className="mainTitle">Отзывы покупателей</h2>
      <ReviewsV2 />
    </div>
  );
};
