import { useReactiveVar, makeVar } from "@apollo/client";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useMemo } from "react";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
} from "recharts";
import {
  getReviewsStatistics,
  getReviewsStatisticsKey,
} from "../../api/rest/getReviewsStatistics";
import { FullContainerSpinner } from "../../components/common/fullContainerSpinner";
import { SearchNotFound } from "../../components/common/searchNotFound";
import {
  SelectList,
  ISelectedListItem,
} from "../../components/common/selectList";
import { chosenStore } from "../../store/stores.store";
import Star from "../../assets/icons/starFilled.svg?react";

import "../../styles/pages/reviewsPage.css";
import { ratingElements } from "./config";

const items = [
  {
    name: "За сегодня",
    value: moment().format("YYYY.MM.DD"),
    id: "1day",
  },
  {
    name: "За два дня",
    value: moment().subtract(1, "day").format("YYYY.MM.DD"),
    id: "2days",
  },
];

const statisticsFilter = makeVar<{
  minDate: ISelectedListItem;
  rating?: number;
}>({
  minDate: items[0],
});

const CustomizedTick = (props: any) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} fill="rgba(var(--dark-100))">
        <tspan textAnchor="middle" x="0">
          {payload.value}
          <div>123</div>
        </tspan>
      </text>
      <g transform="translate(4, 2) scale(0.75)">
        <Star className="reviews-statistics-page-graph-star" />
      </g>
    </g>
  );
};

const ReviewsStatisticsList = () => {
  const { minDate, rating: filterRating } = useReactiveVar(statisticsFilter);
  const store = useReactiveVar(chosenStore);

  const { data, isLoading } = useQuery({
    queryFn: () =>
      getReviewsStatistics({
        storeId: store.id,
        minDate: minDate.value.toString(),
      }),
    queryKey: [...getReviewsStatisticsKey, JSON.stringify({ minDate, store })],
    enabled: !!store?.id,
  });

  const dataToUse = useMemo(() => {
    const items = [];

    for (const date in data) {
      const skuToRatings = data[date];

      for (const sku in skuToRatings) {
        const ratings = skuToRatings[sku];
        const i = [];

        for (const rating in ratings) {
          const ratingInfo = ratings[rating];

          if (filterRating && +rating !== filterRating) continue;

          i.push({ date, rating, ratingInfo, count: ratingInfo.length });
        }

        items.push({ items: i, date });
      }
    }

    return items;
  }, [data, filterRating]);

  if (isLoading) return <FullContainerSpinner />;
  if (dataToUse.length === 0) return <SearchNotFound />;

  return (
    <div className="reviews-statistics-page-items">
      {dataToUse.map((i, idx) => {
        const product = i.items[0]?.ratingInfo[0];
        if (!i.items?.length) return null;
        return (
          <div className="reviews-statistics-page-item" key={idx}>
            <div className="reviews-statistics-page-item-left">
              <img loading="lazy" src={product.product.cover_image} alt="" />
              <div>SKU: {product.sku}</div>
              <div>Дата: {moment(i.date).format("DD.MM.YYYY")}</div>
            </div>
            <div className="w-full h-full">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={i.items}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    color="rgba(var(--dark-100))"
                    tick={<CustomizedTick />}
                    dataKey="rating"
                  />
                  <YAxis
                    color="rgba(var(--dark-100))"
                    tickCount={i.items.length}
                    domain={[0, "dataMax"]}
                  />
                  <Bar
                    isAnimationActive={false}
                    dataKey="count"
                    fill="rgba(var(--accent1-70))"
                    name="Количество оценок"
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const ReviewsStatisticsPage = () => {
  const filterStatistics = useReactiveVar(statisticsFilter);

  const clearSelectRating = () => {
    const newFilter = { ...filterStatistics };
    delete newFilter.rating;

    statisticsFilter(newFilter);
  };

  return (
    <div className="default-page reviews-statistics-page">
      <h2 className="mainTitle">Статистика по отзывам</h2>
      <div className="reviews-filters">
        <SelectList
          active={filterStatistics.minDate}
          elements={items}
          handleChange={(i) =>
            statisticsFilter({ ...filterStatistics, minDate: i })
          }
        />
        <SelectList
          active={ratingElements.find(
            (i) => i.value === filterStatistics?.rating,
          )}
          placeholder="Оценка"
          elements={ratingElements}
          handleClear={clearSelectRating}
          handleChange={(item) => {
            statisticsFilter({
              ...filterStatistics,
              rating: +item.value,
            });
          }}
        />
      </div>
      <ReviewsStatisticsList />
    </div>
  );
};
