import { useEffect, useState } from "react";

export const useDebounce = (value: string, delay = 500, initial = "") => {
  const [debouncedValue, setDebouncedValue] = useState(initial);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return { debouncedValue, setDebouncedValue };
};
